var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.module && _vm.canShowModule && _vm.isShowModule
    ? _c(
        _vm.actualFrame,
        {
          directives: [
            {
              name: "animate-obsever",
              rawName: "v-animate-obsever",
              value: { module: _vm.module, projectid: _vm.projectid },
              expression: "{ module, projectid }",
            },
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          key: _vm.id,
          ref: "jzModule",
          tag: "component",
          staticClass: "form jz_module",
          class: _vm.moduleClass,
          style: _vm.wrapStyle,
          attrs: {
            id: _vm.moduleId,
            rowid: _vm.rowid,
            projectid: _vm.projectid,
            "module-index": _vm.moduleIndex,
            module: "",
          },
          nativeOn: {
            dblclick: function ($event) {
              $event.stopPropagation()
              return _vm.moduleEdit.apply(null, arguments)
            },
            mouseleave: function ($event) {
              return _vm.showSideModule(false, _vm.id)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "module_wrap",
              class: {
                J_project_sort_handle: _vm.canSort,
                layout_module_warp: _vm.isLayoutModule,
              },
              style: _vm.moduleWrapStyle,
            },
            [
              _vm.bannerType
                ? _c(
                    "div",
                    { staticClass: "module_banner", style: _vm.bannerStyle },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "module_banner_text jz_theme_font_color",
                          style: _vm.titleStyle,
                        },
                        [_vm._v(_vm._s(_vm.decodeTitle))]
                      ),
                      _vm._v(" "),
                      _vm.moduleStatus === 1
                        ? _c(
                            "svg",
                            {
                              staticClass: "lock_module_close_icon",
                              on: { click: _vm.hideLockModule },
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon_close" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.moreType
                        ? _c(
                            "a",
                            {
                              staticClass: "module_banner_more",
                              style: _vm.bannerMoreStyle,
                              attrs: {
                                href: _vm.moreUrl,
                                rel: _vm.moreRel,
                                target: _vm.moreTarget,
                              },
                            },
                            [
                              _vm.isMobi
                                ? [
                                    _c(
                                      "svg",
                                      {
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px",
                                          color: "#999",
                                        },
                                        attrs: { viewBox: "0 0 16 16" },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M10.85,8.15l-4-4a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7L9.79,8.5,6.15,12.15a.5.5,0,1,0,.7.7l4-4a.48.48,0,0,0,0-.7Z",
                                            fill: "currentColor",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                : [_vm._v(" " + _vm._s(_vm.LS.more) + " ")],
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "module_content",
                  class: _vm.moduleContentClass,
                  style: _vm.contentStyle,
                },
                [
                  _c(_vm.actualCompon, {
                    tag: "component",
                    attrs: {
                      id: _vm.id,
                      projectid: _vm.projectid,
                      module: _vm.module,
                      rowid: _vm.rowid,
                      "module-index": _vm.moduleIndex,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.moduleStatus === 2
            ? _c(
                "div",
                {
                  staticClass: "side_btn jz_theme_bg_color",
                  class: _vm.sideBtnClass,
                  on: {
                    mouseenter: function ($event) {
                      return _vm.showSideModule(true)
                    },
                  },
                },
                [
                  _vm._l(_vm.decodeTitle.slice(0, 15), function (item) {
                    return _c("div", [
                      _vm._v("\n            " + _vm._s(item) + "\n        "),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.decodeTitle.length > 15
                    ? _c("div", [_vm._v("...")])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.moduleStatus === 1 && !_vm.bannerType
            ? _c(
                "svg",
                {
                  staticClass:
                    "lock_module_close_icon lock_module_close_icon--no-title",
                  on: { click: _vm.hideLockModule },
                },
                [_c("use", { attrs: { "xlink:href": "#icon_close" } })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.manageMode
            ? _c("features", {
                attrs: { inner: "", features: _vm.innerFeatures },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }