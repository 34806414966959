import { deepFreeze } from '@jz/utils';
/**
 * @author mackie
 * @private
 * @description
 * 定义模块名字跟svgclass的数据 原先的JZ.getSvgName Comm.getModuleStyleName
 * 都从这去获取 避免添加模块/漏加上
 * {
 *  id: module.style,
 *  svgClass: svg icon class(指添加模块面板的svg),
 *  moduleComponentName: 模块的组件名,
 *  moduleComponent: 模块的组件, 不需要懒加载的需要在 jzModule 注册, 直接在这里 import会报错, 不常用的可以通过懒加载的方式引入
 *  moduleCnName: 模块中文名（目前就编辑面板的标题）,
 *  sysId: module.id (系统模块id), 系统模块通过模块id获取
 * }
 */
const MODULE_STYLE_DATA = deepFreeze([
    { id: -1, svgClass: 'fullmeasurePack', moduleCnName: '通栏排版' },
    {
        id: 1,
        svgClass: 'rich',
        moduleComponentName: 'rich',
        moduleCnName: '图文展示',
        moduleComponent: () => import(/* webpackChunkName: "module_rich" */ '../rich/index.vue'),
    },
    {
        id: 2,
        svgClass: 'carousel_photos',
        moduleComponentName: 'carouselPhotos',
        moduleComponent: () => import(/* webpackChunkName: "module_carouselPhotos" */ '../carouselPhotos/index.vue'),
        moduleCnName: '轮播多图',
    },
    {
        id: 3,
        svgClass: 'list_photos',
        moduleComponentName: 'listPhotos',
        moduleComponent: () => import(/* webpackChunkName: "module_listPhotos" */ '../listPhotos/index.vue'),
        moduleCnName: '列表多图',
    },
    {
        id: 4,
        moduleComponentName: 'newsDetail',
        moduleComponent: () => import(/* webpackChunkName: "module_newsDetail" */ '../newsDetail/index.vue'),
        moduleCnName: '文章详情',
    },
    {
        id: 5,
        moduleComponentName: 'newsResult',
        moduleComponent: () => import(/* webpackChunkName: "module_newsResult" */ '../newsResult/index.vue'),
        moduleCnName: '文章筛选',
    },
    {
        id: 6,
        svgClass: 'simpleText',
        moduleComponentName: 'textModule',
        moduleCnName: '文本',
    },
    {
        id: 7,
        svgClass: 'floatImg',
        moduleComponentName: 'imageModule',
        moduleCnName: '图片',
    },
    {
        id: 8,
        svgClass: 'floatBtn',
        moduleComponentName: 'buttonModule',
        moduleCnName: '按钮',
    },
    {
        id: 9,
        moduleComponentName: 'productDetail',
        moduleComponent: () => import(/* webpackChunkName: "module_productDetail" */ '../productDetail/index.vue'),
        moduleCnName: '产品详情',
    },
    {
        id: 10,
        moduleComponentName: 'productResult',
        moduleComponent: () => import(/* webpackChunkName: "module_productResult" */ '../productResult/index.vue'),
        moduleCnName: '产品筛选',
    },
    {
        id: 11,
        moduleComponentName: 'memberSignup',
        moduleComponent: () =>
            import(/* webpackChunkName: "module_memberSignup" */ '../memberSignup/moduleMemberSignup.vue'),
        moduleCnName: '会员注册',
    },
    {
        id: 12,
        moduleComponentName: 'memberLogin',
        moduleComponent: () => import(/* webpackChunkName: "module_memberLogin" */ '../memberLogin/index.vue'),
        moduleCnName: '会员登录',
    },
    {
        id: 13,
        moduleComponentName: 'memberCenter',
        moduleComponent: () =>
            import(/* webpackChunkName: "module_moduleMemberCenter" */ '../memberCenter/moduleMemberCenter.vue'),
        moduleCnName: '会员中心',
    },
    {
        id: 14,
        svgClass: 'newsgroup',
        moduleComponentName: 'categoryNavModule',
        moduleComponent: () => import(/* webpackChunkName: "module_categoryNav" */ '../categoryNav/index.vue'),
        moduleCnName: '文章分类',
    },
    {
        id: 15,
        svgClass: 'photoGroup',
        moduleComponentName: 'photoGroup',
        moduleComponent: () => import(/* webpackChunkName: "module_photoGroup" */ '../photoGroup/index.vue'),
        moduleCnName: '图册目录',
    },
    {
        id: 16,
        svgClass: 'product',
        moduleComponentName: 'productList',
        moduleComponent: () => import(/* webpackChunkName: "module_productList" */ '../productList/index.vue'),
        moduleCnName: '产品展示',
    },
    {
        id: 17,
        svgClass: 'article',
        moduleComponentName: 'newsList',
        moduleComponent: () => import(/* webpackChunkName: "module_newsList" */ '../newsList/index.vue'),
        moduleCnName: '文章列表',
    },
    {
        id: 18,
        svgClass: 'productNav',
        moduleComponentName: 'categoryNavModule',
        moduleComponent: () => import(/* webpackChunkName: "module_categoryNav" */ '../categoryNav/index.vue'),
        moduleCnName: '产品分类',
    },
    {
        id: 19,
        svgClass: 'map',
        moduleComponentName: 'onlineMap',
        moduleComponent: () => import(/* webpackChunkName: "module_onlineMap" */ '../onlineMap/index.vue'),
        moduleCnName: '在线地图',
    },
    {
        id: 20,
        svgClass: 'flv',
        moduleComponentName: 'flv',
        moduleComponent: () => import(/* webpackChunkName: "module_flv" */ '../flv/index.vue'),
        moduleCnName: '在线视频',
    },
    {
        id: 21,
        svgClass: 'siteForm',
        moduleComponentName: 'onlineForm',
        moduleComponent: () => import(/* webpackChunkName: "module_onlineForm" */ '../onlineForm/index.vue'),
        moduleCnName: '在线表单',
    },
    {
        id: 22,
        moduleComponentName: 'msgBoard',
        moduleComponent: () => import(/* webpackChunkName: "module_moduleMsgBoard" */ '../moduleMsgBoard/index.vue'),
        moduleCnName: '留言栏目',
    },
    {
        id: 23,
        svgClass: 'msgSubmit',
        moduleComponentName: 'moduleMsgBoard',
        moduleComponent: () => import(/* webpackChunkName: "module_msgBoard" */ '../msgBoard/index.vue'),
        moduleCnName: '留言提交',
    },
    {
        id: 24,
        svgClass: 'customSearch',
        moduleComponentName: 'customSearch',
        moduleComponent: () => import(/* webpackChunkName: "module_customSearch" */ '../customSearch/index.vue'),
        moduleCnName: '信息查询',
    },
    {
        id: 25,
        moduleComponentName: 'customSearchResult',
        moduleComponent: () =>
            import(/* webpackChunkName: "module_customSearchResult" */ '../customSearchResult/index.vue'),
        moduleCnName: '信息查询结果',
    },
    {
        id: 26,
        svgClass: 'iframe',
        moduleComponentName: 'iframeModule',
        moduleComponent: () => import(/* webpackChunkName: "module_iframe" */ '../iframe/index.vue'),
        moduleCnName: '嵌入页面',
    },
    {
        id: 27,
        svgClass: 'panelFile',
        moduleComponentName: 'fileDownload',
        moduleComponent: () => import(/* webpackChunkName: "module_fileDownload" */ '../fileDownload/index.vue'),
        moduleCnName: '文件下载',
    },
    {
        id: 28,
        svgClass: 'code',
        moduleComponentName: 'pluginCode',
        moduleComponent: () => import(/* webpackChunkName: "module_pluginCode" */ '../pluginCode/index.vue'),
        moduleCnName: '插件代码',
    },
    {
        id: 29,
        svgClass: 'siteSearch',
        moduleComponentName: 'siteSearch',
        moduleComponent: () => import(/* webpackChunkName: "module_siteSearch" */ '../siteSearch/index.vue'),
        moduleCnName: '全站搜索',
    },
    {
        id: 30,
        moduleComponentName: 'searchResult',
        moduleComponent: () => import(/* webpackChunkName: "module_searchResult" */ '../searchResult/index.vue'),
        moduleCnName: '全站搜索结果',
    },
    {
        id: 31,
        svgClass: 'serviceOnline',
        moduleComponentName: 'onlineService',
        moduleComponent: () => import(/* webpackChunkName: "module_onlineService" */ '../onlineService/index.vue'),
        moduleCnName: '在线客服',
    },
    {
        id: 32,
        svgClass: 'tabPackHorizonal',
        moduleComponentName: 'tabpack',
        moduleComponent: () => import(/* webpackChunkName: "module_tabpack" */ '../tabpack/index.vue'),
        moduleCnName: '标签模块',
    },
    {
        id: 33,
        svgClass: 'icons',
        moduleComponentName: 'iconCombination',
        moduleComponent: () => import(/* webpackChunkName: "module_iconCombination" */ '../iconCombination/index.vue'),
        moduleCnName: '图标组合',
    },
    {
        id: 34,
        svgClass: 'current_position',
        moduleComponentName: 'currentPosition',
        moduleComponent: () => import(/* webpackChunkName: "module_currentPosition" */ '../currentPosition/index.vue'),
        moduleCnName: '当前位置',
    },
    {
        id: 35,
        svgClass: 'dynamicNumber',
        moduleComponentName: 'dynamicNumber',
        moduleComponent: () => import(/* webpackChunkName: "module_dynamicNumber" */ '../dynamicNumber/index.vue'),
        moduleCnName: '动态数字',
    },
    {
        id: 36,
        moduleComponentName: 'photoGroupDetail',
        moduleComponent: () =>
            import(/* webpackChunkName: "module_photoGroupDetail" */ '../photoGroupDetail/index.vue'),
        moduleCnName: '图册详情',
        sysId: 12,
    },
    {
        id: 37,
        svgClass: 'timeline',
        moduleComponentName: 'timeline',
        moduleComponent: () => import(/* webpackChunkName: "module_timeline" */ '../timeline/index.vue'),
        moduleCnName: '时间轴',
    },
    {
        id: 38,
        svgClass: 'friendLink',
        moduleComponentName: 'friendLink',
        moduleComponent: () => import(/* webpackChunkName: "module_friendLink" */ '../friendLink/index.vue'),
        moduleCnName: '友情链接',
    },
    {
        id: 39,
        svgClass: 'categoryNav',
        moduleComponentName: 'categoryNavModule',
        moduleComponent: () => import(/* webpackChunkName: "module_categoryNav" */ '../categoryNav/index.vue'),
        moduleCnName: '栏目导航',
    },
    {
        id: 40,
        svgClass: 'sitemap',
        moduleComponentName: 'sitemap',
        moduleComponent: () => import(/* webpackChunkName: "module_sitemap" */ '../sitemap/index.vue'),
        moduleCnName: '网站地图',
    },
    {
        id: 41,
        svgClass: 'accordion',
        moduleComponentName: 'accordion',
        moduleComponent: () => import(/* webpackChunkName: "module_accordion" */ '../accordion/index.vue'),
        moduleCnName: '手风琴',
    },
    {
        id: 42,
        svgClass: 'rollingNotice',
        moduleComponentName: 'rollingNotice',
        moduleComponent: () => import(/* webpackChunkName: "module_rollingNotice" */ '../rollingNotice/index.vue'),
        moduleCnName: '滚动公告',
    },
    {
        id: 43,
        moduleComponentName: 'mallOrderDetail',
        moduleComponent: () => import(/* webpackChunkName: "module_mallOrderDetail" */ '../mallOrderDetail/index.vue'),
        moduleCnName: '订单详情',
    },
    {
        id: 44,
        moduleComponentName: 'placeOrder',
        moduleComponent: () => import(/* webpackChunkName: "module_placeOrder" */ '../placeOrder/index.vue'),
        moduleCnName: '下单详情',
    },
    { sysId: 150, moduleComponentName: 'nav' },
    { sysId: 151, moduleComponentName: 'Footnav' },
    { sysId: 152, moduleComponentName: 'Suspendserv' },
]);

/**
 * @function name - getModuleTargetName
 * @param param0 {Object}
 * @description
 * {
 *  id: 指需要获取数据的模块id或者模块style,
 *  key: 指MODULE_STYLE_DATA数据根据模块id获取（sysId） 还是模块style获取 （id）,
 *  targetKey: 指需要获取的数据的对象属性名
 * }
 * 具体可看MODULE_STYLE_DATA 具体内容
 */
export const getModuleTargetName = ({ id, key = 'id', targetKey = 'moduleComponentName' }) =>
    MODULE_STYLE_DATA.filter((value) => value[key] == Number(id))?.[0]?.[targetKey] || 'none';

export const getModuleVisitorPresetData = (style) => {
    const key = style >= 150 ? 'sysId' : 'id';
    const target = MODULE_STYLE_DATA.find((value) => value[key] == Number(style)) || {};
    let component = target.moduleComponent;
    if (typeof component === 'function') {
        component = () => {
            return target.moduleComponent().then((component) => {
                if (typeof window === 'object' && window._store?.state.manageMode) {
                    window._store.dispatch('manage/pauseDataWatch');
                }
                return component;
            });
        };
    }
    return {
        style: target.id,
        component,
        componentName: target.moduleComponentName,
        name: target.moduleCnName,
    };
};
