import { getOneSiteForm } from '@/api/form/index.js';
import { PopupWin } from '@/site/shared/popupWin/index.js';
import { context as globalContext } from '@jz/utils';
import { createDialog } from '@/site/shared/dialog/index.js';
const LS = globalContext.LS || {};

function getSiteForm(id) {
    return (getSiteForm.promise = new Promise(function (resolve, reject) {
        if (typeof Fai.top.linkFormList == 'undefined') {
            Fai.top.linkFormList = {};
        }
        if (Fai.top.linkFormList[id]) {
            resolve(Fai.top.linkFormList[id]);
            return;
        }
        getOneSiteForm({
            formId: id,
            ram: Math.random(),
        })
            .then((result) => {
                var info = result.info;
                Fai.top.linkFormList[id] = info;
                resolve(info);
            })
            .catch((err) => {
                reject(err);
            });
    }));
}

export const openSiteForm = function (id, pid, fromBtn = false) {
    getSiteForm(id).then(async (formInfo) => {
        const manageMode = window._store.state.manageMode;
        manageMode && (await getSiteFormList());
        const isMobi = window._store.state.device === 'mobi';
        if (window._store.state.openMultiPdInquiry && fromBtn) {
            let inquiryProductList = JSON.parse(localStorage.getItem('inquiry_product_list') || '[]');

            if (manageMode) {
                inquiryProductList = [];
            }

            inquiryProductList.unshift(pid);

            inquiryProductList = [...new Set(inquiryProductList)];

            if (inquiryProductList.length <= 50) {
                localStorage.setItem('inquiry_product_list', JSON.stringify(inquiryProductList));
            }

            if (inquiryProductList.length === 50) {
                openPopupWin(id, formInfo, fromBtn);
                return;
            }
            const confirmButtonText = LS.continue_visit || '继续浏览';
            const cancelButtonText = LS.inquiry || '立即询盘';
            if (!isMobi && !$('.c_popup').length) {
                createDialog({
                    content: LS.add_inquiry_list || '已添加进询盘列表',
                    type: 'success',
                    autoHide: false,
                    confirmButtonText,
                    cancelButtonText,
                    onConfirm: () => {},
                    onCancel: () => {
                        openPopupWin(id, formInfo, fromBtn);
                    },
                    onClose: () => {},
                    layoutClass: 'inquiry_popup',
                });
            }
            if (isMobi && !$('.jz-modal-root').length) {
                Vue.prototype.$modal.show({
                    title: LS.add_inquiry_list || '已添加进询盘列表',
                    onCancel() {},
                    onConfirm() {
                        openPopupWin(id, formInfo, fromBtn);
                    },
                    env: 'mobile',
                    getContainer() {
                        return document.querySelector('.jz_preview_area');
                    },
                    confirmButtonText: cancelButtonText,
                    cancelButtonText: confirmButtonText,
                });
            }
        } else {
            openPopupWin(id, formInfo, fromBtn);
        }
    });
};
async function openPopupWin(id, formInfo, fromBtn) {
    let opts,
        module = {},
        options = {},
        formDataList;
    module.prop0 = 0;
    module.prop2 = id;
    module.prop3 = {
        url: '',
    };
    module.isLinkToForm = true;
    module.fromBtn = fromBtn;
    module.privatePattern = {
        pc: {
            itt: {
                y: 0,
            },
        },
    };
    const isMultiPdInquiry = window._store.state.openMultiPdInquiry && fromBtn;
    if (isMultiPdInquiry) {
        const inquiryProductList = JSON.parse(localStorage.getItem('inquiry_product_list') || '[]');
        const {
            data: { pdList },
        } = await jzRequest.get('/ajax/product_h.jsp', {
            params: {
                cmd: 'getWafNotCk_getMulitProductInquiryPdList',
                idList: JSON.stringify(inquiryProductList),
            },
        });
        Vue.set(module, 'inquiryProductList', pdList);
    }
    formDataList = Fai.top.linkFormList[id];
    formDataList.formData = formInfo;

    if (Comm.getDevice() === 'pc') {
        formInfo.prop1 = formInfo.buttonName;
    } else {
        formInfo.prop1 = LS.siteFormSubmit;
    }
    $.extend(formDataList, module);
    opts = {
        width: 900,
        height: 588,
        component: () => import(/* webpackChunkName: 'onlineForm' */ '@/components/modules/onlineForm/index.vue'),
        module: formDataList,
        props: {
            module: formDataList,
        },
        options,
    };

    if (!formDataList.other.p.n) {
        formDataList.other.p.n = LS.popupFormSubmit;
    }

    formDataList.prop1 = formDataList.buttonName;
    formDataList.prop3.url = formDataList.other.p.lu;
    const popupWinVm = PopupWin(opts);

    if (!Fai.top.formDataList) {
        Fai.top.formDataList = new Map();
        Fai.top.popupWinVmList = new Map();
        Fai.top.popupWinVmList.set(formDataList.id, popupWinVm);
        Fai.top.formDataList.set(formDataList.id, formDataList);
    } else {
        Fai.top.popupWinVmList.set(formDataList.id, popupWinVm);

        if (!Fai.top.formDataList.has(formDataList.id)) {
            Fai.top.formDataList.set(formDataList.id, formDataList);
        }
    }
}
